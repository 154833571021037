/*
 * @Author: 陈俊升 761723995@qq.com
 * @Date: 2023-10-31 15:53:35
 * @LastEditors: 陈俊升 761723995@qq.com
 * @LastEditTime: 2024-02-04 16:52:57
 * @FilePath: /hr_new_vue3/src/apis/management/base/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/* 储存通用了一些接口 */
import { ajaxService } from '@/utils/request';
import { ajaxUploadService } from '@/utils/requestUpload';

const env = import.meta.env;
const baseURL = env.VITE_APP_BASE_URL;
const baseURL2 = env.VITE_UPLOAD_URL;

// 文件上传
export function PostFile(data: any) {
  return ajaxService({
    url: '/lke_file/add',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8'
    },
    baseURL: baseURL2,
    data
  }
  ,
  { message: '上传成功', mode: 'success' });
}

// 文件上传(断点续传)
export function postFileUpload(data: any) {
  return ajaxService({
    url: '/lke_file/breakpoint/resume/upload',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8'
    },
    // onUploadProgress: (progressEvent) => {
    //   console.log(1, progressEvent);
    // },
    baseURL: baseURL2,
    data
  });
}

// 聊天记录上传
export function postFileUploadManagement(data: any) {
  return ajaxService<{ fileUrl?: string | undefined; fileName: string }>({
    url: '/lke_file/breakpoint/resume/upload',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8'
    },
    baseURL: baseURL2,
    data
  });
}

// 上传头像
export function PostAvatar(data: any) {
  return ajaxService({
    url: '/hr/personnel_matters/roster/personnel/photo',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8'
    },
    baseURL,
    data
  });
}

// 获取表头
/* 
  ownerFuncType
  区分模块 1：组织 2：职位  3人员 4待入职 5与入职 6入职办理 7放弃入职 8申请入职 9离职申请 10待离职 11已离职 12标准职位库
*/
export function GetHeaderSetting(params: any) {
  return ajaxService({
    url: '/hr/header_config/header',
    method: 'get',
    baseURL: baseURL,
    params
  });
}

// 新增表头
export function PostHeaderSetting(data: any) {
  return ajaxService({
    url: '/hr/header_config/header',
    method: 'post',
    baseURL: baseURL,
    data
  });
}

// 修改表头
export function PutHeaderSetting(data: any) {
  return ajaxService({
    url: '/hr/header_config/header',
    method: 'put',
    baseURL: baseURL,
    isLoading: true,
    data
  });
}

// 新增导入模板
export function PostImportTemplate(data: any) {
  return ajaxService({
    url: '/excel/diy',
    method: 'post',
    baseURL: baseURL,
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8'
    },
    responseType: 'blob',
    data
  });
}
